import React, {useState} from 'react'
import './about.css'
import MyPhoto from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'
import {BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom';

const About = () => {
    return (
        <section id='about'>
            <h5>Get To Know</h5>
            <h2>About Me</h2>

            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={MyPhoto} alt=""/>
                    </div>
                </div>
                <div className="about__content">
                    <div className="about__cards">
                        <article className="about__card">
                            <FaAward className="about__icon"/>
                            <h5>Experience</h5>
                            <small>6+ Years Working</small>
                        </article>
                        <article className="about__card">
                            <VscFolderLibrary className="about__icon"/>
                            <h5>Projects</h5>
                            <small>20+ Completed</small>
                        </article>
                    </div>
                    <p>Hello! I am an experienced front-end developer with over six years of professional experience.
                        My career began as a freelancer, and now I hold the position of front-end developer.
                    </p>
                    <p>
                        My main responsibilities include: <br/>
                        Implementing website layouts according to design using Pixel Perfect principles.
                        Developing responsive websites of varying complexity based on approved design layouts.
                        Integrating layouts on the WordPress platform, customizing fields, and providing full site
                        management through the admin panel.
                        Close collaboration with the design team to ensure flawless project execution.
                        Developing projects using Vue.js, optimizing performance through code refactoring and
                        optimization.
                        Providing support and editing for ongoing projects.
                        In the Laravel admin panel, I used Vue 2 to create a map feature that allowed users to create
                        and manage route maps for tours. I worked closely with design and backend teams to successfully
                        complete projects on time and within budget.
                        I also refactored ESLint settings and mentored junior developers.
                    </p>
                    <p> In the Laravel admin panel, I used Vue 2 to create a map feature that allowed users to create
                        and manage route maps for tours. I worked closely with design and backend teams to successfully
                        complete projects on time and within budget.
                    </p>
                    <p>
                        I excel in teamwork and am always looking for innovative solutions to complex problems. I am
                        proficient in English, which allows me to efficiently read documentation and leave clear
                        comments in the code.
                    </p>
                    <a href="mailto:kalinka03333@gmail.com" className='btn btn-primary'>Let's Talk</a>
                </div>
            </div>
        </section>
    )
}
export default About