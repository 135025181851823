import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio8.jpg'
import IMG5 from '../../assets/portfolio5.jpg'
import IMG6 from '../../assets/portfolio6.jpg'
import IMG7 from '../../assets/portfolio9.jpg'
import IMG8 from '../../assets/portfolio10.jpg'
import IMG9 from '../../assets/portfolio11.jpg'
import IMG10 from '../../assets/portfolio12.jpg'
import IMG11 from '../../assets/portfolio13.jpg'
import IMG12 from '../../assets/portfolio15.jpg'
import IMG13 from '../../assets/portfolio17.jpg'
import IMG14 from '../../assets/portfolio18.jpg'


const data = [
    {
        id: 1,
        image: IMG1,
        title: 'Türkiye deki gayrimenkul pazarlama ve',
        demo: 'https://proje-pazarlama.com/tr'
    },
    {
        id: 2,
        image: IMG2,
        title: 'Plant & Flask',
        demo: 'https://plantandflask.com/'
    },
    {
        id: 3,
        image: IMG3,
        title: 'Praktyk online store — reliability and quality of each product',
        demo: 'https://praktyk.com.ua/'
    },
    {
        id: 4,
        image: IMG4,
        title: 'Interior Expert',
        demo: 'https://interior-expert.com/'
    },
    {
        id: 5,
        image: IMG5,
        title: 'Doreale Design',
        demo: 'https://dorealedesign.com.ua/'
    },
    {
        id: 6,
        image: IMG6,
        title: 'Fur shops in Istanbul',
        demo: 'https://leaderfurtransfer.com/'
    },
    {
        id: 7,
        image: IMG7,
        title: 'Property in Turkey with Liga Real Estate',
        demo: 'https://ligarealestate.com/'
    },
    {
        id: 8,
        image: IMG8,
        title: 'Foreign language on Skype',
        demo: 'https://easy2learn.us/'
    },
    {
        id: 9,
        image: IMG9,
        title: 'Professional website promotion in Google shopping system rankings from SEOROST',
        demo: 'https://seorost.com/'
    },
    {
        id: 10,
        image: IMG10,
        title: 'Дизайн інтерєру | Студія дизайну інтерєру',
        demo: 'https://www.perspectiva-design.com/ua/'
    },
    {
        id: 10,
        image: IMG11,
        title: 'Nordic Property',
        demo: 'https://nordicproperty.com.tr/tr'
    },
    {
        id: 11,
        image: IMG12,
        title: 'Tour GARDA',
        demo: 'https://tour-garda.com/hotels/'
    },
    {
        id: 12,
        image: IMG13,
        title: 'Tuncay & Barcın',
        demo: 'https://tuncaybarcinlawoffice.com/'
    },
    {
        id: 13,
        image: IMG14,
        title: 'Investments into movable property',
        demo: 'https://newlevel-dream.com/'
    }

]

const Portfolio = () => {
    return (
        <section id='portfolio'>
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>

            <div className="container portfolio__list">
                {
                    data.map(({id, image, title, demo}) => {
                        return (
                            <article key={id} className="portfolio__item">
                                <div className="portfolio__item-photo">
                                    <img src={image} alt=""/>
                                </div>
                                <p>{title}</p>
                                <a href={demo} className="btn btn-primary">
                                    Site
                                </a>
                            </article>
                        )
                    })
                }
            </div>

        </section>
    )
}
export default Portfolio